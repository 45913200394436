import React from "react"
import {FcAddImage} from "@react-icons/all-files/fc/FcAddImage";


const FileUpload = ({}) =>{
    return (
        <div className={"file_upload_container"}>
            <FcAddImage size={40} style={{marginRight:30}}/>
            <span style={{color:"blue"}} className={"font_montserrat w_700"}>Загрузите или перетащите сюда файлы</span>
        </div>
    )
}
export default FileUpload