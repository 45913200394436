import React from "react"
import {Button} from "@vkontakte/vkui";

export default ({setVisible}) => (
    <div className={"content"}>
        <span className={"alert_title"} id={"error"}>Ошибка:
            <span style={{color: "red"}}>

            </span>
        </span>
        <div className={"popout_button"}>
            <Button onClick={()=>setVisible(false)} mode={"destructive"} size={"l"}>Закрыть</Button>
        </div>

    </div>
)