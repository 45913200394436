import React from "react"

export default ({}) =>(
    <div className={"content"}>
        <span className={"alert_title"}>Загрузка файлов</span>
        <div className={"file_progress"}>
            <span id={"prog_upload"}>0%</span>
            <progress/>
        </div>

    </div>
)