import React, {useState} from "react"
import {AiOutlineCloseCircle} from "react-icons/ai";
import {Button, FormItem, Input} from "@vkontakte/vkui";

const AddType = ({callback,closePop}) =>{
    const [name,setName] = useState("")
    return (
        <div className={"content add_type_content"}>
            <div style={{ width: "95%", display: "flex",alignContent:"center", justifyContent: "flex-end"}}>
                <span className={"font_montserrat w_700"} style={{fontSize:25,justifySelf:"center"}}>Создание типа бумаги</span>
                <button onClick={closePop} style={{position:"relative",alignSelf:"flex-end",justifySelf:"flex-end"}}
                        className={"close_cropik"}><AiOutlineCloseCircle color={"red"}
                                                                         size={30}/></button>
            </div>
            <div className={"create_content"}>
                <FormItem style={{width:"100%"}} top={"Тип бумаги"}>
                    <Input value={name} onChange={(e)=>setName(e.currentTarget.value)}/>
                </FormItem>
                <Button size={"l"} stretched mode={"commerce"} onClick={()=>callback(name)}>Добавить</Button>
            </div>
        </div>
    )
}
export default AddType