import React, {useEffect, useState} from "react"
import {
    Button,
    CellButton,
    IconButton, Input,
    Panel,
    Placeholder,
    ScreenSpinner,
    SimpleCell,
    Snackbar, Switch,
    View
} from "@vkontakte/vkui";
import CustomPopout from "../components/CustomPopout";
import "../css/admin.css"
import {
    Icon24ChevronDown,
    Icon24ChevronUp,
    Icon28ChevronDownOutline,
    Icon28ChevronUpOutline,
    Icon28Delete
} from '@vkontakte/icons';
import {GiEmptyWoodBucket} from "react-icons/gi";
import Admin from "../rest/Admin";
import AddType from "../AdminComponents/AddType";
import AddSize from "../AdminComponents/AddSize";
import AddShop from "../AdminComponents/AddShop";

const AdminPanel = ({id}) => {
    const [alert_content, setAlertContent] = useState(null)
    const [snack, setSnack] = useState(null)
    const [popout, setPopout] = useState(<ScreenSpinner/>)
    const [visible, setVisible] = useState(false)
    const [openTypes, setOpenTypes] = useState(false)
    const [openSizes, setOpenSizes] = useState(false)
    const [openShops, setOpenShops] = useState(false)
    const [sizes, setSizes] = useState([])
    const [types, setTypes] = useState([])
    const [shops, setShops] = useState([])
    useEffect(() => {
        Admin.getInfo().then((r) => {
            setSizes(r.data.sizes)
            setTypes(r.data.types)
            setShops(r.data.shops)
            setPopout(null)
        }).catch((err) => {
            alert(`Ошибка загрузки, ошибка - ${err.message}`)
        })
    }, [])
    const closePop = () => {
        Admin.getInfo().then((r) => {
            setSizes(r.data.sizes)
            setTypes(r.data.types)
            setShops(r.data.shops)
            setPopout(null)
        }).catch((err) => {
            alert(`Ошибка загрузки, ошибка - ${err.message}`)
        })
        setVisible(false)
        setPopout(null)
        setAlertContent(null)
    }
    const addNewType = (name) => {
        setAlertContent(null)
        setVisible(false)
        if (!name)
            setSnack(<Snackbar onClose={() => setSnack(null)}>Не сохранено</Snackbar>)
        else {
            setPopout(<ScreenSpinner/>)
            Admin.sendRequest({type: "add_type", label: name}).then((r) => {
                setTypes(r.data.types)
                setPopout(null)
                setSnack(<Snackbar onClose={() => setSnack(null)}>Сохранено</Snackbar>)
            }).catch((err) => {
                setPopout(null)
                setSnack(<Snackbar onClose={() => setSnack(null)}>Ошибка,{err.message}</Snackbar>)
            });
        }
    }
    const addNewSize = (data) => {
        setAlertContent(null)
        setVisible(false)
        setPopout(<ScreenSpinner/>)
        Admin.sendRequest(
            {type: "add_size", ...data}
        ).then((r) => {
            setSizes(r.data.sizes)
            setPopout(null)
            setSnack(<Snackbar onClose={() => setSnack(null)}>Сохранено</Snackbar>)
        }).catch((err) => {
            setPopout(null)
            setSnack(<Snackbar onClose={() => setSnack(null)}>Ошибка,{err.message}</Snackbar>)
        });

    }

    const createType = () => {
        setAlertContent(<AddType closePop={closePop} callback={(name) => addNewType(name)}/>)
        setVisible(true)
    }
    const createSize = () =>{
        setAlertContent(<AddSize types={types} closePop={closePop} callback={(name) => addNewSize(name)}/>)
        setVisible(true)
    }
    const callbackAddShop = (name,adres,phone,time) =>{
        setAlertContent(null)
        setVisible(false)
        setPopout(<ScreenSpinner/>)
        Admin.sendRequest(
            {type: "add_shop", name, adres,phone, time}
        ).then((r) => {
            setShops(r.data.shops)
            setPopout(null)
            setSnack(<Snackbar onClose={() => setSnack(null)}>Сохранено</Snackbar>)
        }).catch((err) => {
            setPopout(null)
            setSnack(<Snackbar onClose={() => setSnack(null)}>Ошибка,{err.message}</Snackbar>)
        });
    }
    const addShop = () =>{
        setAlertContent(<AddShop closePop={closePop} callback={(name,adres,phone,time) => callbackAddShop(name,adres,phone,time)}/>)
        setVisible(true)
    }
    const deleteType = (key) =>{
        let tp = types[key];
        if (confirm(`Удалить - ${tp.label}?`)) {
           Admin.sendRequest({type:"delete_type",_id:tp._id}).then((r)=>{
               setTypes(r.data.types)
               setPopout(null)
               setSnack(<Snackbar onClose={() => setSnack(null)}>Удалено</Snackbar>)
           })
        }

    }
    const deleteSize = (_id) =>{
        if (confirm(`Удалить?`)) {
            Admin.sendRequest({type:"delete_size",_id:_id}).then((r)=>{
                setSizes(r.data.sizes)
                setPopout(null)
                setSnack(<Snackbar onClose={() => setSnack(null)}>Удалено</Snackbar>)
            })
        }
    }
    const deleteShop =(_id)=>{
        if (confirm(`Удалить?`)) {
            Admin.sendRequest({type:"delete_shop",_id:_id}).then((r)=>{
                setShops(r.data.shops)
                setPopout(null)
                setSnack(<Snackbar onClose={() => setSnack(null)}>Удалено</Snackbar>)
            })
        }
    }
    const disableSize = (id) =>{
        Admin.disableSize(id).then((r)=>{
            setSizes(r.data.sizes)
        })
    }
    const openEditSize = (_id) =>{
        setAlertContent(<AddSize edit={true} _id={_id} sizes={sizes} types={types} closePop={closePop} callback={(name) => addNewSize(name)}/>)
        setVisible(true)
    }
    const sortUp = (_id) =>{
        setPopout(<ScreenSpinner/>)
        Admin.sendRequest({type:"up_size",_id:_id}).then((r)=>{
            setSizes(r.data.sizes)
            setPopout(null)
        }).catch(r=>setPopout(null))
    }
    const sortDown = (_id) =>{
        setPopout(<ScreenSpinner/>)
        Admin.sendRequest({type:"down_size",_id:_id}).then((r)=>{
            setSizes(r.data.sizes)
            setPopout(null)
        }).catch(r=>setPopout(null))
    }
    const sort = (_id,sort) =>{
        Admin.sendRequest({type:"sort",_id:_id,sort}).then((r)=>{
            setSizes(r.data.sizes)
            setPopout(null)
        }).catch(r=>setPopout(null))
    }
    return (
        <View popout={visible ? <CustomPopout content={alert_content}/> : popout} activePanel={id}>
            <Panel id={id}>
                <div className={"admin_header"}>
                    <span className={"font_montserrat w_700"} style={{fontSize: 20}}>
                        Административная часть
                    </span>
                </div>
                <div className={"admin_content"}>
                    <CellButton style={{backgroundColor: openTypes ? "rgba(80,255,49,.2)" : "rgba(200,200,200,.2)"}}
                                onClick={() => setOpenTypes(prevState => !prevState)}
                                className={"open_me"} after={!openTypes ? <Icon24ChevronUp/> : <Icon24ChevronDown/>}>
                        Типы бумаги
                    </CellButton>
                    {openTypes &&
                    <div className={"list_"}>

                        <div className={"types_admin"}>
                            {types && types.length > 0 ?
                                types.map((item, key) => (
                                    <div key={key} className={"row_"}>
                                        <span>{item.label}</span>
                                        <IconButton onClick={()=>deleteType(key)} className={"delte_but"}>
                                            <Icon28Delete width={20} height={20} color={"red"}/>
                                        </IconButton>
                                    </div>
                                )) :
                                <Placeholder  icon={<GiEmptyWoodBucket size={70}/>}>
                                    Пока тут ничего нет
                                </Placeholder>
                            }
                        </div>

                        <Button onClick={createType} style={{alignSelf: "center"}} size={"m"} mode={"commerce"}>Добавить
                            тип бумаги</Button>
                    </div>
                    }

                    <CellButton
                        style={{backgroundColor: openSizes ? "rgba(80,255,49,.2)" : "rgba(200,200,200,.2)"}}
                        onClick={() => setOpenSizes(prevState => !prevState)} className={"open_me"}
                        after={!openSizes ? <Icon24ChevronUp/> : <Icon24ChevronDown/>}>
                        Размеры
                    </CellButton>
                    {openSizes &&
                    <div className={"list_"}>
                        <div className={"types_admin sizes_listing"} style={{flexDirection:"column"}}>
                            {sizes && sizes.length > 0 ?
                                sizes.map((item, key) => (
                                    <div className={"size_div"}>
                                        <SimpleCell
                                            style={{flex:1}}
                                            onClick={()=>openEditSize(item._id)}
                                            className={"size_cell"}
                                            description={`${item.width}x${item.height} - Печать: ${item.allow_types.label}`}
                                        >
                                            {item.label}
                                        </SimpleCell>
                                        <Switch style={{cursor:"pointer"}} onChange={()=>disableSize(item._id)} checked={item.allow}/>
                                        <IconButton onClick={()=>deleteSize(item._id)} className={"delte_but"}>
                                            <Icon28Delete width={20} height={20} color={"red"}/>
                                        </IconButton>
                                        <Input
                                            onChange={(e)=>{
                                                let vl = e.currentTarget.value;
                                                if(vl && vl.length > 0 && parseInt(vl)){
                                                    sort(item._id,parseInt(vl))
                                                }
                                            }}
                                            type={"number"}
                                            style={{maxWidth:70}}
                                               value={item.hasOwnProperty("sort") ? item.sort : 0}
                                        />
                                        {/*{key !== 0 &&
                                        <IconButton onClick={()=>sortUp(item._id)} style={{color:"green"}} className={"delte_but"}>
                                            <Icon28ChevronUpOutline width={20} height={20} color={"green"}/>
                                        </IconButton>
                                        }
                                        {key !== sizes.length &&
                                        <IconButton onClick={()=>sortDown(item._id)} className={"delte_but"}>
                                            <Icon28ChevronDownOutline width={20} height={20} color={"red"}/>
                                        </IconButton>
                                        }*/}

                                    </div>

                                )) :
                                <Placeholder icon={<GiEmptyWoodBucket size={70}/>}>
                                    Пока тут ничего нет
                                </Placeholder>
                            }
                        </div>
                        <Button style={{alignSelf: "center"}} size={"m"} mode={"commerce"} onClick={createSize}>Добавить размер</Button>
                    </div>
                    }
                    <CellButton
                        style={{backgroundColor: openShops ? "rgba(80,255,49,.2)" : "rgba(200,200,200,.2)"}}
                        onClick={() => setOpenShops(prevState => !prevState)} className={"open_me"}
                        after={!openShops ? <Icon24ChevronUp/> : <Icon24ChevronDown/>}>
                        Магазины
                    </CellButton>
                    {openShops &&
                    <div className={"list_"}>
                        <div className={"types_admin"}>
                            {shops && shops.length > 0 ?
                                shops.map((item, key) => (
                                    <div key={key} className={"row_"}>
                                        <div style={{display:"flex",flexDirection:"column"}}>
                                            <span>{item.name}</span>
                                            <span style={{fontSize:12,color:"grey"}}>{item.adres}</span>
                                            <span style={{fontSize:12,color:"grey"}}>{item.phone}</span>
                                            <span style={{fontSize:12,color:"grey"}}>{item.time}</span>
                                        </div>
                                        <IconButton onClick={()=>deleteShop(item._id)} className={"delte_but"}>
                                            <Icon28Delete width={20} height={20} color={"red"}/>
                                        </IconButton>
                                    </div>
                                )) :
                                <Placeholder icon={<GiEmptyWoodBucket size={70}/>}>
                                    Пока тут ничего нет
                                </Placeholder>
                            }
                        </div>
                        <Button onClick={addShop} style={{alignSelf: "center"}} size={"m"} mode={"commerce"}>Добавить магазин</Button>
                    </div>
                    }
                </div>
                {snack}
            </Panel>
        </View>
    )
}
export default AdminPanel