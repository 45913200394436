import React, {useEffect, useState} from "react"
import {AiOutlineCloseCircle} from "react-icons/ai";
import {Button, CustomSelectOption, NativeSelect, FormItem, Input, Select, IconButton} from "@vkontakte/vkui";
import {Icon20Check, Icon28Delete} from "@vkontakte/icons";

const AddSize = ({callback,sizes,edit,_id, closePop, types}) => {
    const [name, setName] = useState("")
    const [allow_types, setAllowTypes] = useState("")
    const [folder_name, setFolderName] = useState("")
    const [min_size, setMinSize] = useState("200")
    const [width, setWidth] = useState("")
    const [height, setHeight] = useState("")

    const [cost1_10, setCost1_10] = useState("")
    const [cost11_50, setCost11_50] = useState("")
    const [cost51_100, setCost51_100] = useState("")
    const [cost_over_100, setCost_over_100] = useState("")
    const close = () => {
        setAllowTypes([])
        closePop();
    }
    useEffect(() => {
        setAllowTypes(types)
    }, [])
    const getAllowTypes = () => {
        return types;
    }
    const save = () => {
        if(!name)
        {
            alert("Введите наименование")
            return false;
        }
        if(!allow_types)
        {
            alert("Выберите тип бумаги")
            return false;
        }
        if(!folder_name)
        {
            alert("Введите название папки")
            return false;
        }
        if(!min_size)
        {
            alert("Введите минимальное разрешение")
            return false;
        }
        if(!width || !height)
        {
            alert("Укажите X и Y")
            return false;
        }
        if(!cost1_10 || !cost11_50 || !cost51_100 || !cost_over_100)
        {
            alert("Укажите все ценновые диапазоны")
            return false;
        }
        callback({label:name,allow_types,folder_name,min_size,width,height,cost1_10,cost11_50,cost51_100,cost_over_100,_id,edit})
    }
    useEffect(()=>{
       if(edit === true){
           let f = sizes.find(x=>x._id === _id)
           setName(f.label);
           setAllowTypes({...f.allow_types});
           setFolderName(f.folder_name);
           setMinSize(f.min_size);
           setWidth(f.width);
           setHeight(f.height);
           setCost1_10(f.cost1_10);
           setCost11_50(f.cost11_50);
           setCost51_100(f.cost51_100);
           setCost_over_100(f.cost_over_100);
       }
    },[])
    const selectType = (key) => {
        setAllowTypes(types.find(x=>x.label === key.target.value))
    }
    return (
        <div className={"content add_type_content size"}>
            <div style={{width: "95%", display: "flex", alignContent: "center", justifyContent: "flex-end"}}>
                <span className={"font_montserrat w_700"}
                      style={{fontSize: 25, justifySelf: "center"}}>{edit === true ? "Редактирование":"Создание"} размера</span>
                <button onClick={close}
                        style={{position: "relative", alignSelf: "flex-end", justifySelf: "flex-end"}}
                        className={"close_cropik"}><AiOutlineCloseCircle color={"red"}
                                                                         size={30}/></button>
            </div>
            <div className={"create_content"}>
                <FormItem style={{flex:.4}} top={"Наименование"}>
                    <Input value={name} onChange={(e) => setName(e.currentTarget.value)}/>
                </FormItem>
                {/* <div className={"selected_d"}>
                    {allow_types && allow_types.map((item, key) => {
                        if (item.selected)
                            return (
                                <div key={key} className={"this_dk"}>
                                    {item.label}
                                    <IconButton onClick={() => deleteType(key)} className={"delte_but"}>
                                        <Icon28Delete width={20} height={20} color={"red"}/>
                                    </IconButton>
                                </div>
                            )
                    })

                    }
                </div>*/}

                <FormItem style={{flex:.4}} top={"Название папки"}>
                    <Input value={folder_name} onChange={(e) => setFolderName(e.currentTarget.value)}/>
                </FormItem>
                <FormItem style={{flex:.4}} top={"Минимальное разрешение изображения"}>
                    <Input value={min_size} onChange={(e) => setMinSize(e.currentTarget.value)}/>
                </FormItem>
                {types &&
                <FormItem style={{width: "100%"}}
                          top={`Выберите доступный тип бумаги.`}>
                    <NativeSelect value={allow_types && allow_types.label} placeholder={"Выберите тип"} onChange={selectType}>
                        {types && types.map((item, key) => {
                            return (
                                <option key={key} value={item.label}>{item.label}</option>
                            )
                        })

                        }
                    </NativeSelect>
                </FormItem>
                }
                <div style={{display: "flex",width:"100%"}}>
                    <FormItem style={{width: "100%"}} top={"Размер X, мм"}>
                        <Input value={width} onChange={(e) => setWidth(e.currentTarget.value)}/>
                    </FormItem>
                    <FormItem style={{width: "100%"}} top={"Размер Y, мм"}>
                        <Input value={height} onChange={(e) => setHeight(e.currentTarget.value)}/>
                    </FormItem>
                </div>

                <FormItem top={"Цена 1-10 шт"}>
                    <Input value={cost1_10} onChange={(e)=>setCost1_10(e.currentTarget.value)}/>
                </FormItem>
                <FormItem top={"Цена 11-50 шт"}>
                    <Input value={cost11_50} onChange={(e)=>setCost11_50(e.currentTarget.value)}/>
                </FormItem>
                <FormItem top={"Цена 51-100 шт"}>
                    <Input value={cost51_100} onChange={(e)=>setCost51_100(e.currentTarget.value)}/>
                </FormItem>
                <FormItem top={"Цена >100 шт"}>
                    <Input value={cost_over_100} onChange={(e)=>setCost_over_100(e.currentTarget.value)}/>
                </FormItem>
                <Button size={"l"} stretched mode={"commerce"} onClick={save}>{edit ? "Сохранить" : "Добавить"}</Button>
            </div>
        </div>
    )
}
export default AddSize