import React, {useEffect, useRef, useState} from "react";
import {AiFillCheckCircle, AiOutlineRotateLeft} from "react-icons/ai";
import {RiCrop2Line, RiDeleteBin6Line} from "react-icons/ri";
import {BiCopy} from "react-icons/bi";
import file from "../img/miobmob.png"
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Image from "../rest/Image";
import ReactCrop, {centerCrop, makeAspectCrop} from "react-image-crop";
import {FormItem, Input, Slider} from "@vkontakte/vkui";
import InsertAlert from "./InsertAlert";
import {AiOutlineRotateRight} from "react-icons/ai";
import CropImage from "./CropImage";

const File = ({wh, defaultSize, popout, defaultPols, deleteFile, saveFile, copyFile, types, item, sizes, openCrop, canvasWidth, selectFile, selectedFiles, photo}) => {
    const [crop, setCrop] = useState(item.hasOwnProperty("crop") ? item.crop : null)
    const [fileDeleted, setFileDeleted] = useState(false)
    const [rotate, setRotate] = useState(item.hasOwnProperty("rotate") ? item.rotate : false)
    const [canvasSizes, setCanvasSizes] = useState()
    const [selectedSize, setSelectedSize] = useState(item.hasOwnProperty("selectedSize") ? item.selectedSize : sizes[0])

    const [imageSize, setImageSize] = useState(item.hasOwnProperty("imageSize") ? item.imageSize : null)

    const [polsValue, setPolsValue] = useState(item.hasOwnProperty("polsValue") ? item.polsValue : "С полями")
    const [typeValue, setTypeValue] = useState(item.hasOwnProperty("typeValue") ? item.typeValue : types[0])
    const imgRef = useRef();
    const [imgRef1, setImagRef1] = useState(null);
    const [cropImage, setCropImage] = useState(item.hasOwnProperty("cropImage") ? item.cropImage : null)
    const previewCanvasRef = useRef();
    const bezPolsCanvas = useRef();
    const sizesDrop = sizes.map(x => x.label);

    useEffect(() => {
        saveFile("imageSize", imageSize)
        saveFile("polsValue", polsValue)
        saveFile("cropImage", cropImage)
        saveFile("typeValue", typeValue)
        saveFile("selectedSize", selectedSize)
        saveFile("rotate", rotate)
        saveFile("crop", crop)

    }, [imageSize, polsValue, cropImage, typeValue, selectedSize, rotate, crop])
    useEffect(() => {
        if (defaultSize) {
            setSelectedSize(defaultSize)
        }
    }, [])
    useEffect(() => {
        console.log(!item.hasOwnProperty("owner_key") && item.polsValue !== "С полями")
        if (defaultPols && imageSize && selectedSize && imgRef1 && !item.hasOwnProperty("owner_key")) {
            if (item.hasOwnProperty("owner_key"))
                selectPols({value: defaultPols}, false)
            else
                selectPols({value: defaultPols}, true)
        } else if (item.hasOwnProperty("owner_key") && item.polsValue !== "С полями") {
            selectPols({value: item.polsValue}, false)
        }
    }, [imageSize, selectedSize, imgRef1])
    const type = [
        'Глянцеваяя', 'Матовая'
    ];
    const pols = [
        'Без полей', 'С полями'
    ];
    const borders = [
        'Без рамки', 'Рамка Instagram', 'Рамка Polaroid'
    ];
    const defaultType = type[0];
    const defaultBorders = borders[0];
    const onImageLoad = (e) => {
        imgRef.current = e.currentTarget;

        const {width, height} = e.currentTarget
        if (!imageSize)
            setImageSize({width: width, height: height})


        saveFile("canvas", previewCanvasRef.current)
        saveFile("canvas_bz", bezPolsCanvas.current)
        /*if (width >= height) {
            setAspect(height / width)
        }*/
        Image.initCanvas(previewCanvasRef.current, e.currentTarget, true, selectedSize)
        const cropd = centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 100,
                },
                selectedSize.width / selectedSize.height,
                width,
                height
            ),
            width,
            height
        )
        //setCropImage(cropd)
        setCrop(cropd)
        if (imageSize && polsValue !== "С полями") {

            /*setCrop(item.hasOwnProperty("crop") ? item.crop : cropd)
            openCrop(saveMe, imageSize, selectedSize, item.hasOwnProperty("crop") ? item.crop : cropd)*/
        }
        setImagRef1(e.currentTarget)

    }

    useEffect(() => {
        if (polsValue === "С полями") {
            setRotate(false)
            saveFile("rotate", false)
            Image.initCanvas(previewCanvasRef.current, imgRef.current, true, selectedSize, null, false)
            //Image.cropCanvas( document.querySelector(`.result_canvas_${wh}`),imgRef,cropImage)
        } else {

        }
    }, [selectedSize])

    const saveMe = (crop, imgRefd) => {
        imgRef.current = imgRefd.current;
        if (crop.unit === "%") {
            setCrop(crop)
        } else
            setCropImage(crop)
    }
    useEffect(() => {

    }, [cropImage, imgRef, previewCanvasRef])
    const callback = (crop) => {
        setCrop(crop)
    }
    const selectPols = (e, mb = false) => {
        if (!imgRef.current)
            return false;
        setPolsValue(e.value)
        saveFile("pols", e.value)
        if (e.value === "С полями") {
            setRotate(false)
            saveFile("rotate", false)
            Image.initCanvas(previewCanvasRef.current, imgRef.current, true, selectedSize, null, false)
        } else {
            const cropd = centerCrop(
                makeAspectCrop(
                    {
                        unit: '%',
                        width: 100,
                    },
                    imgRef.current.width >= imgRef.current.height ? selectedSize.height / selectedSize.width : selectedSize.width / selectedSize.height,
                    imgRef.current.width,
                    imgRef.current.height
                ),
                imgRef.current.width,
                imgRef.current.height
            )
            //setCropImage(cropd)
            setCrop(cropd)
            Image.initCanvas(previewCanvasRef.current, imgRef.current, false, selectedSize, callback)

            if (!mb)
                openCrop(saveMe, imageSize, selectedSize, cropd, true)
            else {
                //openMeCrop(saveMe, imageSize, selectedSize, null,true)
                let width = imgRef.current.width;
                let height = imgRef.current.height;
                let cropInit = cropd;

                let w = width * (cropInit.width / 100)
                let h = height * (cropInit.height / 100)
                let aspect;
                if (width >= height) {//Горизонтальные и квадратные фотки
                    if (w >= h)
                        aspect = width >= height ? selectedSize.height / selectedSize.width : selectedSize.width / selectedSize.height
                    else
                        aspect = width >= height ? selectedSize.width / selectedSize.height : selectedSize.height / selectedSize.width
                } else {//Вертикальные
                    if (w >= h)
                        aspect = width >= height ? selectedSize.width / selectedSize.height : selectedSize.height / selectedSize.width
                    else
                        aspect = width >= height ? selectedSize.height / selectedSize.width : selectedSize.width / selectedSize.height
                }
                let crop_px = centerCrop(
                    makeAspectCrop(
                        {
                            unit: 'px',
                            width: imgRef.current.naturalWidth,
                        },
                        aspect,
                        width,
                        height
                    ),
                    width,
                    height
                )

                Image.cropCanvas(document.querySelector(`.result_canvas_${wh}`), imgRef, crop_px)

            }

        }
    }
    const [cropHI, sertCr] = useState(null)
    const openMeCrop = (saveMe, imageSize, selectedSize, cropd, hidden) => {
        sertCr(
            <CropImage hidden={hidden} imageSize={imageSize} selectedSize={selectedSize} cropInit={crop}
                       closePop={(id) => sertCr(null)} savePop={(crop, imgRef) => {
                saveMe(crop, imgRef)
            }} dk={wh} file={item}/>
        )
    }
    const selectSize = (e) => {
        /* if(selectedSize.label === e.value)
             return false
         alert("DFIFIDFI")*/
        let findSize = sizes.find(x => x.label === e.value);
        saveFile("size", e.value)
        setSelectedSize(findSize)
        const cropd = centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 100,
                },
                findSize.width / findSize.height,
                imgRef.current.width,
                imgRef.current.height
            ),
            imgRef.current.width,
            imgRef.current.height
        )
        //setCropImage(cropd)
        setCrop(cropd)
        if (polsValue !== "С полями") {
            openCrop(saveMe, imageSize, findSize)
            Image.initCanvas(previewCanvasRef.current, imgRef.current, false, findSize, callback)
        } else
            Image.initCanvas(previewCanvasRef.current, imgRef.current, true, findSize, callback)


    }

    const getWidthAndHeight = () => {
        if (polsValue !== "С полями") {
            if (imageSize.width > imageSize.height) {
                return {
                    width: canvasWidth * selectedSize.coefficient,
                    height: (canvasWidth * selectedSize.coefficient) * (imageSize.height / imageSize.width)
                }
            } else {
                let imgCoef = imageSize.height / imageSize.width;
                let height = canvasWidth * selectedSize.coefficient;
                return {width: height / imgCoef, height: height}
            }
        }

        if (imageSize) {
            if (imageSize.width <= imageSize.height) {
                if (rotate) {

                    return {width: canvasWidth * selectedSize.coefficient, height: canvasWidth}
                } else
                    return {width: canvasWidth, height: canvasWidth * selectedSize.coefficient}
            }

            return {width: canvasWidth * selectedSize.coefficient, height: canvasWidth}

        }


        return {width: canvasWidth * selectedSize.coefficient, height: canvasWidth}
    }
    const handleClick = (e) => {

        switch (e.detail) {
            case 1:
                if (polsValue !== "С полями")
                    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
                        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
                        openCrop(saveMe, imageSize, selectedSize, crop, false)
                    }
                break
            case 2:
                if (polsValue !== "С полями") {
                    openCrop(saveMe, imageSize, selectedSize, crop, false)

                }
                break;
        }
    }
    useEffect(() => {
        if (rotate) {
            Image.initCanvas(previewCanvasRef.current, imgRef.current, true, selectedSize, null, true)
            /* if(polsValue === "С полями"){
                 Image.initCanvas(previewCanvasRef.current, imgRef.current, true, selectedSize,null,rotate)
             }*/
        } else {
            Image.initCanvas(previewCanvasRef.current, imgRef.current, true, selectedSize, null, false)

        }
    }, [rotate])
    useEffect(() => {
        let canvas = document.querySelector(`.result_canvas_${wh}`);
        let ctx = canvas.getContext('2d')
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
    }, [])
    return (
        <div className={"file"} style={{display: fileDeleted ? "none" : "flex"}}>
            <div className={"file_buttons"}>
                {/*<button onClick={selectFile}>
                    <AiFillCheckCircle color={selectedFiles.indexOf(wh) !== -1 ? "green" : "black"} size={20}/>
                </button>*/}
                <button disabled={!imageSize || polsValue === "С полями"}
                        onClick={() => openCrop(saveMe, imageSize, selectedSize, crop, false)}>
                    <RiCrop2Line size={20}/>
                </button>
                <button
                    disabled={!polsValue || polsValue === "Без полей" || !imageSize || (imageSize.width !== imageSize.height)}
                    onClick={() => {
                        saveFile("rotate", !rotate)
                        setRotate(prevState => !prevState)
                    }}>
                    {rotate ?
                        <AiOutlineRotateLeft size={20}/> :
                        <AiOutlineRotateRight size={20}/>
                    }
                </button>
                <button onClick={() => copyFile()}>
                    <BiCopy size={20}/>
                </button>
                <button onClick={() => {
                    saveFile("delete", true)
                    setFileDeleted(true)
                }}>
                    <RiDeleteBin6Line size={20} color={"red"}/>
                </button>
            </div>
            <button className={"file_photo2"} onClick={handleClick}>
                <ReactCrop
                    disabled={true} crop={crop}
                    onComplete={c => {

                        setCropImage(c)

                    }}
                    onChange={c => {
                        setCrop(c)
                    }}
                    aspect={
                        imageSize && selectedSize &&
                        ((imageSize.width >= imageSize.height) || (rotate && imageSize.width === imageSize.height) ? selectedSize.width / selectedSize.height : selectedSize.height / selectedSize.width)
                    }
                    style={
                        {
                            width: getWidthAndHeight().width/*getWidthAndHeight().width + 40*/,
                            height: getWidthAndHeight().height,
                        }
                    }
                    className={`file_photo ${polsValue === "С полями" ? "no_crop_preview" : ""} ${rotate ? 'rotate' : ''}`}>

                    <img style={{
                        display: polsValue === "С полями" ? "none" : "block", height: "100%", position: "absolute"
                    }} onLoad={onImageLoad} src={Image.getFiles(photo)}/>

                    {selectedSize && imageSize && (selectedSize.minSize >= Image.getMinSize(imageSize) ?
                        <InsertAlert selectedSize={selectedSize.minSize} Image={Image.getMinSize(imageSize)}
                                     imageSize={imageSize}/> : null)}
                    <canvas

                        className={"photo"}
                        ref={previewCanvasRef}
                        style={{
                            opacity: polsValue === "С полями" ? 1 : 0
                            // Rounding is important for sharpness.

                        }}
                    />
                </ReactCrop>
            </button>
            <canvas ref={bezPolsCanvas} className={`result_canvas_${wh}`}
                    style={
                        {
                            alignSelf: "center",
                            display: "none",
                            width: cropImage && cropImage.width / selectedSize.coefficient,
                            height: cropImage && cropImage.height / selectedSize.coefficient,
                        }
                    }
            />
            {item && item.hasOwnProperty("keke") && (item.keke)}


            <div className={"file_down"}>

                <Dropdown controlClassName={"d53"} className={"d3"} options={sizesDrop} onChange={selectSize}
                          value={selectedSize.label}
                          placeholder="Выберите размер"/>


                {/* <Dropdown options={types} value={typeValue} onChange={(e) => {
                    setTypeValue(e.value)
                    saveFile("type", e.value)
                }} placeholder="Выберите тип"/>*/}
                <Dropdown controlClassName={"d53"} style={{width: "100%", textAlign: "center"}}
                          placeholderClassName={"place_31"}
                          className={"d3"} options={pols} value={polsValue}
                          onChange={selectPols} placeholder="Выберите поля"/>
                {/*{polsValue !== "С полями" &&
                <span className={"font_montserrat w_700"}
                      style={{color: "red", textAlign: "center", marginBottom: 20, maxWidth: "100%", fontSize: 13}}>Не забудьте сохранить кадрирование</span>
                }*/}

                <div style={{height: "40px !important"}} className={"count_of_file"}>
                    <button
                        onClick={() => {
                            if (item.hasOwnProperty("count") && item.count > 1)
                                saveFile("count", item.count - 1)
                        }}
                        style={{
                            fontSize: 25,
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column"
                        }} className={"font_montserrat w_700"}>
                        <span>-</span>
                    </button>
                    <input
                        style={{
                            height: 35.6
                        }}
                        onChange={(e) => {
                            saveFile("count", parseInt(e.currentTarget.value) ? parseInt(e.currentTarget.value) : 1)
                        }}
                        className={"font_montserrat w_700 count_input"} value={item.count ? item.count : 1}/>
                    <button
                        onClick={() => {
                            saveFile("count", item.hasOwnProperty("count") ? item.count + 1 : 2)
                        }}
                        style={{
                            fontSize: 25,
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column"
                        }} className={"font_montserrat w_700"}>
                        <span>+</span>
                    </button>
                </div>

            </div>
            {cropHI}
        </div>
    )
}
export default File