import axios from 'axios';
import http from "./create_axios";

class User {
    getInfo(){
        return http.post("/api",{type:"get_information"});
    }
    createOrder(data){
        return http.post("/api",{type:"new_order",data:data});
    }
    deleteTemp(photos){
        return http.post("/api",{type:"delete_temp",photos});
    }
    sendOrderPhoto(photo, orderId){
        return http.post("/api",{type:"send_photo",orderId,photo});
    }
}
export default new User();