import React, {useEffect, useRef, useState} from "react"
import Image from "../rest/Image";
import ReactCrop, {makeAspectCrop, centerCrop, PixelCrop, Crop} from "react-image-crop";
import {Button, ScreenSpinner} from "@vkontakte/vkui";
import {MdOutlineCropRotate} from "react-icons/md";
import {AiOutlineCloseCircle} from "react-icons/ai";

const CropImage = ({file, hidden, dk, cropInit, imageSize, selectedSize, closePop, savePop}) => {
    const [crop, setCrop] = useState()
    const [cropAll, setAllCrop] = useState()
    const [aspect, setAspect] = useState(imageSize.width >= imageSize.height ? selectedSize.height / selectedSize.width : selectedSize.width / selectedSize.height)
    const imgRef = useRef();
    const saveMe = () => {
        let canvas = document.querySelector(`.result_canvas_${dk}`);
        let ctx = canvas.getContext('2d')
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        previewCanvas(canvas)
        savePop(cropAll, imgRef)
    }
    const previewCanvas = (canvas) => {
        const {width, height} = imgRef.current
        const ctx = canvas.getContext('2d')
        console.log(imgRef.current.naturalWidth, imgRef.current.width, cropAll.x)


        let scaleX = imgRef.current.naturalWidth / imgRef.current.width
        let scaleY = imgRef.current.naturalHeight / imgRef.current.height

        ctx.fillStyle = "yellow";
        ctx.fillRect(0, 0, canvas.width, canvas.height);


        let coef = scaleX

        ctx.imageSmoothingQuality = 'high'
        const cropX = (cropAll.hasOwnProperty("x") ? cropAll.x : 1) * scaleX
        const cropY = (cropAll.hasOwnProperty("y") ? cropAll.y : 1) * scaleY

        let cropWidth = cropAll.width
        let cropHeight = cropAll.height

        // const rotateRads = rotate * TO_RADIANS
        const centerX = imgRef.current.width / 2
        const centerY = imgRef.current.height / 2

        ctx.canvas.width = cropWidth * scaleX;
        ctx.canvas.height = cropHeight * scaleY;

        ctx.drawImage(imgRef.current, cropX, cropY, cropWidth * coef, cropHeight * coef, 0, 0, canvas.width, canvas.height)

    }
    const onImageLoad = (e) => {
        imgRef.current = e.currentTarget;
        const {width, height} = e.currentTarget

        if (!cropInit) {
            const cropd = centerCrop(
                makeAspectCrop(
                    {
                        unit: '%',
                        width: 100,
                    },
                    width >= height ? selectedSize.height / selectedSize.width : selectedSize.width / selectedSize.height,
                    width,
                    height
                ),
                width,
                height
            )

            setCrop(cropd)
        } else setCrop(cropInit)
        if (cropInit) {

            let w = width * (cropInit.width / 100)
            let h = height * (cropInit.height / 100)
            let aspect;
            if (width >= height) {//Горизонтальные и квадратные фотки
                if (w >= h)
                    aspect = width >= height ? selectedSize.height / selectedSize.width : selectedSize.width / selectedSize.height
                else
                    aspect = width >= height ? selectedSize.width / selectedSize.height : selectedSize.height / selectedSize.width
            } else {//Вертикальные
                if (w >= h)
                    aspect = width >= height ? selectedSize.width / selectedSize.height : selectedSize.height / selectedSize.width
                else
                    aspect = width >= height ? selectedSize.height / selectedSize.width : selectedSize.width / selectedSize.height
            }
            setAspect(aspect)

        }
        let crop_px = centerCrop(
            makeAspectCrop(
                {
                    unit: 'px',
                    width: imgRef.current.naturalWidth,
                },
                aspect,
                width,
                height
            ),
            width,
            height
        )
        Image.cropCanvas(document.querySelector(`.result_canvas_${dk}`), imgRef, crop_px)

    }
    useEffect(() => {
        if (cropAll) {
            if (hidden) {
                saveMe();
                closePop(dk);
            }
        }
    }, [cropAll])
    const rotateCrop = () => {
        const {width, height} = imgRef.current
        let aspect;
        if (width >= height) {//Горизонтальные и квадратные фотки
            if (cropAll.width >= cropAll.height)
                aspect = width >= height ? selectedSize.width / selectedSize.height : selectedSize.height / selectedSize.width
            else
                aspect = width >= height ? selectedSize.height / selectedSize.width : selectedSize.width / selectedSize.height
        } else {//Вертикальные
            if (cropAll.width >= cropAll.height)
                aspect = width >= height ? selectedSize.height / selectedSize.width : selectedSize.width / selectedSize.height
            else
                aspect = width >= height ? selectedSize.width / selectedSize.height : selectedSize.height / selectedSize.width
        }
        const cropd = centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 100,
                },
                aspect,
                width,
                height
            ),
            width,
            height
        )
        let crop_px = centerCrop(
            makeAspectCrop(
                {
                    unit: 'px',
                    width: imgRef.current.naturalWidth,
                },
                aspect,
                width,
                height
            ),
            width,
            height
        )
        setCrop(cropd)
        setAllCrop(crop_px)
        saveMe();
        savePop(cropd, imgRef)
        setAspect(aspect)
    }
    useEffect(() => {
        if (cropAll)
            saveMe();
    }, [cropAll])
    const getAspect = () => {
        let natWidth = imgRef.current.naturalWidth;
        let natHeight = imgRef.current.naturalHeight;

        let coef
        if (natWidth >= natHeight)
            coef = natWidth / natHeight
        else
            coef = natHeight / natWidth

        if (imgRef.current.naturalWidth > imgRef.current.naturalHeight)
            return imgRef.current.width / coef;
        else
            return imgRef.current.width * coef;


    }
    return (

        <div style={{visibility: hidden ? "hidden" : "visible"}} className={"content image_crop_content"}>
            <div style={{height: 50, padding: 10, width: "95%", display: "flex", justifyContent: "flex-end"}}>
                <button onClick={() => closePop(dk)} className={"close_cropik"}><AiOutlineCloseCircle color={"red"}
                                                                                                      size={30}/>
                </button>


            </div>
            <ReactCrop keepSelection style={{
                aspectRatio: selectedSize.width / selectedSize.height,
                maxHeight: imgRef.current ? getAspect() : "60vh",
                maxWidth:imgRef.current ? imgRef.current.width : "auto"
            }}
                       className={"cropik"} crop={crop}
                       onChange={(_, percentCrop) => {
                           setCrop(percentCrop)
                           savePop(percentCrop, imgRef)
                       }}
                       aspect={aspect}
                       onComplete={c => {
                           setAllCrop(c)
                           savePop(c, imgRef)
                       }}>
                <img style={{aspectRatio: imgRef.current ? getAspect() : 1,transform:"translate(0)"}} onLoad={onImageLoad}
                     src={Image.getFiles(file.photo)}/>
            </ReactCrop>
            <div className="crop_buttons">
                <Button onClick={() => {
                    saveMe()
                    closePop(dk);
                }} size={"l"} stretched mode={"commerce"}>Сохранить</Button>
                <Button onClick={rotateCrop} before={<MdOutlineCropRotate size={25}/>} size={"l"} stretched
                        mode={"secondary"}>Повернуть</Button>
            </div>
        </div>

    )
}
export default CropImage