import React from "react";
import {Button} from "@vkontakte/vkui";


const SuccessCreated = ({success,mess,clearAlert,clearPhotos}) =>{
    return (
        <div className={"content success_content"}>
            <div className={"suc_cont_"}>
                <span style={{fontSize:35,textAlign:"center"}} className={"font_montserrat w_700"}>{success ? "Спасибо за ваш заказ!" : "Ошибка"}</span>
                <span className={"font_montserrat w_600 sc_cr1"} style={{color:success ? "green" : "red",fontSize:14,width:"100%",textAlign:"center"}}>
                    {success ? "Мы ответим на Ваш запрос как можно скорее" : mess}
                </span>
                {success &&
                <span className={"font_montserrat w_700"} style={{color:"black",marginTop:10,fontSize:12,width:"70%",textAlign:"center"}}>
                    P.S. Если с Вами не связались сотрудники фотоцентра для обсуждения деталей заказа, пожалуйста позвоните нам самостоятельно.
                </span>
                }
                <Button onClick={()=> {
                    clearPhotos();
                    clearAlert()
                }} style={{marginTop:15,width:250}} size={"l"} mode={"commerce"}>Ок</Button>
            </div>
        </div>
    )
}
export default SuccessCreated