import React, {useEffect} from "react"
import $ from "jquery"

export default ({content}) => {

        return (
            <div className={"alert_block_upload"}>
                {content}
            </div>
        )

}