import axios from 'axios';
import http from "./create_axios";
import {centerCrop, makeAspectCrop, PixelCrop} from 'react-image-crop'
import {useLayoutEffect} from "react";
import {Alert} from "@vkontakte/vkui";

class Image {
    baseUrl = 'https://print.horosho-photo.ru'

    upload(file, onUploadProgress) {
        let formData = new FormData();
        for (let i = 0; i < file.length; i++) {
            formData.append("file", file[i]);
        }
        return http.post("/upload", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress,
        });
    }

    getFiles(file) {
        return `${this.baseUrl}/temp_static/${file}`;
    }

    initCanvas(canvas, image, pols = false, imgSize, callback = null, rotate) {
        let ctx = canvas.getContext('2d')
        if (!image)
            return;
        if (!ctx) {
            throw new Error('No 2d context')
        }
        ctx.imageSmoothingEnabled = false;
        //const {width, height} = image
        let width = image.naturalWidth;
        let height = image.naturalHeight;

        if (pols) {

            let coef;
            console.log("ПОЛЯ:",pols);
            let height_inches;
            let width_inches;

            let horizontal_image = width>height;
            console.log("ГОРИЗОНТАЛЬНОЕ ИЗОБРАЖЕНИЕ - ",horizontal_image)
            if (horizontal_image) {
                coef = image.width / image.height

                height_inches = imgSize.width / 25.4;
                width_inches = imgSize.height / 25.4;


            } else {
                coef = image.height / image.width

                height_inches = imgSize.height / 25.4;
                width_inches = imgSize.width / 25.4;
            }
            //console.log(`HI = ${height_inches}, WI = ${width_inches}`)
            let scaleX = image.naturalWidth / image.width
            let scaleY = image.naturalHeight / image.height

            let borderstype;
            
            //console.log(coef, imgSize.coefficient, imgSize.width, imgSize.height)
            // если коеф изображения меньше чем коеф печатный
            
            // if (coef< imgSize.coefficient) {
            //     if (horizontal_image)
            //         borderstype = "left_right";
            //     else
            //         borderstype = "top_bottom";
            // } else {
            //    // if(rotate||)//false
            //     if (horizontal_image)
            //         borderstype = "top_bottom";
            //     else
            //         borderstype = "left_right";// !
            // }

            if (!horizontal_image) {
                if (coef <= imgSize.coefficient)
                    borderstype = "top_bottom";
                else
                    borderstype = "left_right";
            } else {
                if (coef<= imgSize.coefficient)
                    borderstype = "left_right";
                else
                    borderstype = "top_bottom";
                }


            console.log(borderstype,`rotate - ${rotate}`)

            if (coef==1 && (rotate)) {borderstype="left_right"}
            //Panyaev
            // if (borderstype === "left_right") {
            //      if (rotate) {
            //        ctx.canvas.width = (image.naturalWidth / width_inches) * height_inches;
            //        ctx.canvas.height = image.naturalWidth;
            //     } else {
            //          if (horizontal_image) {
            //              ctx.canvas.width = (image.naturalHeight / height_inches) * width_inches;
            //              ctx.canvas.height = image.naturalHeight;
            //          } else {
            //
            //                  ctx.canvas.width = (image.naturalHeight / height_inches) * width_inches;
            //                  ctx.canvas.height = image.naturalHeight;
            //              }
            //      }
            // } else { //top_bottom
            //
            //     ctx.canvas.width = (image.naturalWidth);
            //     ctx.canvas.height = ((image.naturalWidth / width_inches) * height_inches);
            //     console.log("Height", ((image.naturalWidth / width_inches) * height_inches))
            // }


            if (!horizontal_image) {
                if (coef == 1) {
                    if (!rotate) {
                        ctx.canvas.width = image.naturalWidth;
                        ctx.canvas.height = (image.naturalWidth / width_inches) * height_inches;
                    } else {
                        ctx.canvas.height =image.naturalWidth;
                        ctx.canvas.width=(image.naturalWidth / width_inches) * height_inches;
                    }
                } else { //коэффициент не 1
                    if (coef <= imgSize.coefficient) {//коэффициент меньше коэффициента печатного, т е изображение ближе к квадрату
                            ctx.canvas.width = image.naturalWidth;
                            ctx.canvas.height = (image.naturalWidth / width_inches) * height_inches;
                    } else { //коэффициент больше печатного, т е изображение ближе к прямоугольнику вытянотому
                        ctx.canvas.width = (image.naturalHeight / height_inches) * width_inches;
                        ctx.canvas.height = image.naturalHeight;
                          console.log(image.naturalHeight,"HEIGHT:", height_inches,"WIDTH:", width_inches, (image.naturalHeight / height_inches) * width_inches);

                    }
                }
            } else { //горизонтальное изображение
                if (coef <= imgSize.coefficient) {//коэффициент меньше коэффициента печатного, т е изображение ближе к квадрату
                    ctx.canvas.height = image.naturalHeight;
                    ctx.canvas.width = (image.naturalHeight / height_inches) * width_inches;
                } else{ //коэффициент больше печатного, т е изображение ближе к прямоугольнику вытянотому
                    ctx.canvas.width = image.naturalWidth;
                    ctx.canvas.height = (image.naturalWidth / width_inches) * height_inches;
                }
            }


            let x;
            let y;

            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            let scale = Math.min(width / image.naturalWidth, height / image.naturalHeight);
            if (horizontal_image) {
                console.log(width, height)
                console.log(canvas.width, canvas.height)
                x = (canvas.width / 2) - (image.naturalWidth / 2) * scale;
                y = (canvas.height / 2) - (image.naturalHeight / 2) * scale;
                ctx.drawImage(image, x, y, image.naturalWidth, image.naturalHeight);
            } else {

                if (borderstype === "left_right") {
                    // x = (canvas.width / 2) - (image.width / 2) * scale;
                    // y = (canvas.height / 2) - (image.height / 2) * scale;
                    // ctx.drawImage(image, x, y, image.naturalWidth * scale, canvas.height * scale);

                         x = (canvas.width / 2) - (image.naturalWidth / 2) * scale;
                         y = (canvas.height / 2) - (image.naturalHeight / 2) * scale;
                         ctx.drawImage(image, x, y, image.naturalWidth * scale, canvas.height * scale);

                } else {

                     x = (canvas.width / 2) - (canvas.width / 2) * scale;
                     y = (canvas.height / 2) - (image.naturalHeight / 2) * scale;
                     ctx.drawImage(image, x, y, canvas.width * scale, image.naturalHeight * scale);
                }
            }
        } else { //без полей

            const {width, height} = image


            /* ctx.canvas.width = width * imgSize.coefficient;
             ctx.canvas.height = canvas.width * imgSize.coefficient;*/
            ctx.canvas.width = image.width
            ctx.canvas.height = image.height

            let coef = height / width;
            let crop_in_percent = centerCrop(
                makeAspectCrop(
                    {
                        unit: '%',
                        width: 100,
                    },
                    width >= height ? imgSize.height / imgSize.width : imgSize.width / imgSize.height,
                    width,
                    height
                ),
                width,
                height
            )
            let crop_px = centerCrop(
                makeAspectCrop(
                    {
                        unit: 'px',
                        width: image.naturalWidth,
                    },
                    width >= height ? imgSize.height / imgSize.width : imgSize.width / imgSize.height,
                    image.width,
                    canvas.height
                ),
                image.width,
                canvas.height
            )
            callback(crop_in_percent)

            //this.cropPreview(image, canvas, crop_px, imgSize)
        }

    }

    cropCanvas(canvas, imgRef, cropAll) {

        const {width, height} = imgRef.current
        const ctx = canvas.getContext('2d')


        let scaleX = imgRef.current.naturalWidth / imgRef.current.width
        let scaleY = imgRef.current.naturalHeight / imgRef.current.height

        ctx.fillStyle = "yellow";
        ctx.fillRect(0, 0, canvas.width, canvas.height);


        let coef = scaleX

        ctx.imageSmoothingQuality = 'high'
        const cropX = (!cropAll.hasOwnProperty("x") ? 1 : cropAll.x) * scaleX
        const cropY = (!cropAll.hasOwnProperty("y") ? 1 : cropAll.y) * scaleY

        let cropWidth = cropAll.width
        let cropHeight = cropAll.height


        // const rotateRads = rotate * TO_RADIANS
        const centerX = imgRef.current.width / 2
        const centerY = imgRef.current.height / 2

        ctx.canvas.width = cropWidth;
        ctx.canvas.height = cropHeight;

        ctx.drawImage(imgRef.current, cropX, cropY, cropWidth * coef, cropHeight * coef, 0, 0, canvas.width, canvas.height)


    }


    cropPreview(
        image,//: HTMLImageElement,
        canvas,//: HTMLCanvasElement,
        crop,//PixelCrop,
        selectedSize,
        scale = 1,
        rotate = 0,
    ) {
        const {width, height} = image
        const ctx = canvas.getContext('2d')
        //const ctx2 = window.document.getElementById("cnv");

        if (!ctx) {
            throw new Error('No 2d context')
        }

        ctx.canvas.width = image.width
        ctx.canvas.height = image.height

        let scaleX = image.naturalWidth / image.width
        let scaleY = image.naturalHeight / image.height

        ctx.fillStyle = "yellow";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        //ctx.scale(pixelRatio, pixelRatio)
        let coef = selectedSize && width >= height ? (crop.width / crop.height) : scaleX

        ctx.imageSmoothingQuality = 'high'
        const cropX = (!crop.hasOwnProperty("x") ? 1 : crop.x) * scaleX
        const cropY = (!crop.hasOwnProperty("y") ? 1 : crop.y) * scaleY

        let cropWidth = crop.width
        let cropHeight = crop.height


        // const rotateRads = rotate * TO_RADIANS
        const centerX = image.width / 2
        const centerY = image.height / 2

        // ctx.save()
        // ctx.translate(centerX, centerY)
        // ctx.rotate(rotateRads)

        if (width > height) {
            ctx.drawImage(image, cropX, cropY, cropHeight * coef, cropWidth * coef, 0, 0, canvas.width, canvas.height)
        } else {
            if (width === height)
                ctx.drawImage(image, cropX, cropY, cropWidth * coef, cropHeight, 0, 0, canvas.width, image.height)
            else {
                ctx.drawImage(image, cropX, cropY, cropWidth * coef, cropHeight * coef, 0, 0, canvas.width, canvas.height)
            }
        }

        // ctx.restore()
    }

    getMinSize(imageSize) {
        if (imageSize.width <= imageSize.height)
            return imageSize.width
        else return imageSize.height
    }

    dlCanvas(canvas) {
        let dt = canvas.toDataURL('image/png');
        let href = dt.replace(/^data:image\/[^;]/, 'data:application/octet-stream');
        window.open(href, '_blank').focus();
    };

}

export default new Image();