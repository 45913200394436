import Slider  from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {FileUploader} from "react-drag-drop-files";

import {
    Panel,
    PanelHeader,
    Header,
    Button,
    Group,
    Cell,
    Div,
    Avatar,
    Alert,
    View,
    Placeholder,
    FormItem, ScreenSpinner
} from '@vkontakte/vkui';
import "../css/home.css"
import FileUpload from "../components/FileUpload";
import {BsFillCartFill, BsInfo} from "react-icons/bs";
import {TiInfoLarge} from "react-icons/ti";
import {RiImageEditFill} from "react-icons/ri";
import Logo from "../img/Logo_Circle_300px.png"
import File from "../components/File";
import Image from "../rest/Image";
import CustomPopout from "../components/CustomPopout";
import FileUploadProgress from "../components/FileUploadProgress";

const fileTypes = ["JPG","JPEG", "PNG", "HEIC", "TIFF", "BMP", "WEBP"];
import $ from "jquery"
import SomeError from "../components/SomeError";
import {VscError} from "react-icons/vsc";
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import CropImage from "../components/CropImage";
import {BsImage} from "react-icons/bs";
import OrderDetails from "../components/OrderDetails";
import TakeOrder from "../components/TakeOrder";
import User from "../rest/User";
import PreviewPhotos from "../components/PreviewPhotos";
import SuccessCreated from "../components/SuccessCreated";
import Dropdown from "react-dropdown";
import {GiRotaryPhone} from "react-icons/gi";
import {BiRotateLeft} from "react-icons/bi";


const Home = ({id, uniqId, go, fetchedUser}) => {
    const [sizes, setSizes] = useState([]/*[
        {
            label:"10x15",
            width:102,
            height:152,
            coefficient:parseFloat(String(152/102)).toFixed(2),
            minSize:200,
        },
        {
            label:"13x18",
            width:130,
            height:180,
            coefficient:parseFloat(String(180/130)).toFixed(2),
            minSize:200,
        },
        {
            label:"15x21",
            width:152,
            height:212,
            coefficient:parseFloat(String(212/152)).toFixed(2),
            minSize:200,
        },
        {
            label:"20x20",
            width:202,
            height:202,
            coefficient:parseFloat(String(202/202)).toFixed(2),
            minSize:200,
        },
    ]*/)
    const [pols, setPols] = useState([
        'Без полей', 'С полями'
    ]);
    const [files, setFiles] = useState([
        /*{photo:"1647198042940-nofoto.jpg"},
        {photo:"1647198042940-nofoto.jpg"},*/
        /*{photo:"1646475500649-logo1.jpg"},
        {photo:"1646583066699-miobmob.png"},
        {photo:"1646669448539-rad.png"},
        {photo:"1646475507798-3zgA6rJADhY.jpg"},
        {photo:"1646681666523-Торт зебраТ.jpg"},*/

    ])
    const filesRef = useRef()
    filesRef.current = files
    const types = [
        {label: "Глянцевая", key: "glan"},
        {label: "Матовая", key: "mat"}
    ]
    const [defaultSize, setDefaultSize] = useState(null)
    const [defaultPols, setDefaultPols] = useState('Без полей')
    const [shops, setShops] = useState([])
    const [selectedFiles, setSelectedFiles] = useState([])
    const [popout, setPopout] = useState(null)
    const [previewPhoto, setPreviewPhoto] = useState(null)
    const [canvasWidth, setCanvasWidth] = useState(150)

    useEffect(() => {
        setPopout(<ScreenSpinner/>)
        User.getInfo().then((r) => {
            setShops(r.data.shops)
            let szs = r.data.sizes.map(x => {
                if (x.allow)
                    return (
                        {...x, coefficient: parseFloat(String(x.height / x.width)).toFixed(2)}
                    )
            })
            setSizes(szs)
            setDefaultSize(szs[0])
            setPopout(null)
        }).catch(() => {
            setPopout(null)
        })
    }, [])
    const saveFile = (key, field, value) => {
        let fls = [...files];
        let file = fls[key];
        if (file) {
            file[field] = value;
            fls[key] = file;
            setFiles([...fls])
        }

    }
    const goBack = () => {
        $(".vkuiView__popout.View__popout").css("display", "block")
        setPreviewPhoto(null)
    }
    const openPreview = (photos) => {
        setPreviewPhoto(<PreviewPhotos sizes={sizes} photos={photos} goBack={goBack}/>)
        $(".vkuiView__popout.View__popout").css("display", "none")
    }
    const selectFile = (key) => {
        let s = selectedFiles;
        if (s.indexOf(key) === -1)
            s.push(key)
        else
            s.splice(s.indexOf(key), 1);
        setSelectedFiles([...s])
    }
    const [alert_content, setAlertContent] = useState(null);
    const [visible, setVisible] = useState(false)

    const handleChange = (file) => {
        if (file && file.length > 0) {
            setVisible(true)
            setAlertContent(<FileUploadProgress/>);
            let currentFiles = file;
            Image.upload(currentFiles, (e) => {
                $("#prog_upload").html(`${Math.round((100 * e.loaded) / e.total)}%`)
            }).then(r => {
                document.querySelector(".file_uploader").firstChild.value = ""
                if (r.data.success && r.data.files.length > 0) {
                    clearAlert();
                    if (filesRef.current && filesRef.current.length > 0) {
                        let d = filesRef.current;
                        let fils = r.data.files.map(x => {
                            return (
                                {
                                    photo: x
                                }
                            )
                        })
                        d = d.concat(fils)
                        setFiles(d)
                    } else {
                        if (r.data.files.length === 1)
                            setFiles([{photo: r.data.files}])
                        else
                            setFiles([...r.data.files.map(
                                x => {
                                    return {
                                        photo: x
                                    }
                                }
                            )])
                    }
                } else {
                    setAlertContent(<SomeError setVisible={setVisible}/>)
                    $("#error").html(
                        "Ошибка:<span style='color: red'>Не удалось загрузить фотографии</span>"
                    )
                }
            }).catch((e) => {
                console.log(e)
                document.querySelector(".file_uploader").firstChild.value = ""
                setAlertContent(<SomeError setVisible={setVisible}/>)
                $("#error").html(
                    "Неизвестная ошибка:<span style='color: red'>" + e.message + "</span>"
                )
            })
        }
    };
    const clearAlert = (id) => {

        setVisible(false)
        setAlertContent(null)
    }
    useEffect(() => {
        /* document.addEventListener('visibilitychange', function() {
             if (document.visibilityState == 'hidden') {

                 User.deleteTemp(filesRef.current.map(x=>x.photo))
             }

         });
         window.addEventListener("unload",(event) => {
             User.deleteTemp(filesRef.current.map(x=>x.photo))
         });*/
    }, [])
    const [history, setHistory] = useState([]);
    const openCrop = (key, saveMe, imageSize, selectedSize, crop, hidden = true) => {

        setAlertContent(<CropImage hidden={hidden} imageSize={imageSize} selectedSize={selectedSize} cropInit={crop}
                                   closePop={(id) => clearAlert(id)} savePop={(crop, imgRef) => {
            saveMe(crop, imgRef)
        }} dk={key} file={files[key]}/>)
        setVisible(true)
    }
    const openDetail = () => {
        setAlertContent(<OrderDetails files={files} sizes={sizes} types={types} closePop={clearAlert}/>)
        setVisible(true)
    }
    const clear_pho = () =>{
        setFiles([])
        document.location.reload();
    }
    const TakeOrderResult = (data) => {
        if (data.success) {
            setAlertContent(<SuccessCreated clearPhotos={clear_pho} mess={data.mess} clearAlert={() => clearAlert()} success={data.success}/>)
        } else {
            setAlertContent(<SuccessCreated clearPhotos={clear_pho} mess={data.mess} clearAlert={() => clearAlert()} success={data.success}/>)
        }
    }
    const openTakeOrder = () => {
        setAlertContent(<TakeOrder callback={(data) => TakeOrderResult(data)} uniqId={uniqId} openPreview={openPreview}
                                   shops={shops} files={files} sizes={sizes} types={types} closePop={clearAlert}/>)
        setVisible(true)
    }
    useEffect(() => {
        if (visible) {
            $('html, body').css({
                overflow: 'hidden',
                height: '100vh'
            });
            $(".vkuiView__popout.View__popout").show();
        } else {
            $('html, body').css({
                overflowY: 'auto',
                overflowX: 'hidden',

            });
        }
    }, [visible])
    const copyFile = (key) => {
        let fil = [...files];
        let file = {...fil[key]}
        file.owner_key = key;
        fil.push(file);

        setFiles([...fil])

    }
    const deleteFile = (key) => {
        let fl = files;
        fl.splice(key, 1);
        setFiles([...fl])
    }
    const auth = (pass) => {

    }
    const getCartCount = () => {
        let p = files.filter(x => !x.hasOwnProperty("delete") || !x.delete);

        return p.map(x => x.count || 1).reduce((partialSum, a) => partialSum + a, 0)
    }
    return (
        <View popout={visible ? <CustomPopout content={alert_content}/> : popout} activePanel={id}>
            <Panel id={id}>
                <div className={"home_header "}>
                    <img src={Logo} className={"logo_head"}/>
                    {/*<h4 style={{margin: 0, fontSize: 20}} className={"font_montserrat w_600"}>Фотопечать</h4>*/}
                    <div className={"header_buttons"}>
                        {/*<button className={"group_edit"}>
                            <RiImageEditFill size={20} style={{marginRight: 10}}/>
                            <span>Групповое изменение</span>
                        </button>*/}
                        {/* <button onClick={openDetail} className={"info"}>
                            <TiInfoLarge size={20} style={{marginRight: 10}}/>
                            <span>Состав заказа</span>
                        </button>*/}
                        <button disabled={!files || files.length === 0} onClick={openTakeOrder} className={"do_order"}>
                            <BsFillCartFill color={"white"} size={20} style={{marginRight: 10}}/>
                            <span>Заказать - {getCartCount()} фото</span>
                        </button>
                    </div>

                </div>

                {/* <div style={{backgroundColor:"red",margin:50,height:152*3.7795275591,width:102*3.7795275591,display:"flex"}}>
                    <div style={{
                        aspectRatio:152/202,
                        backgroundImage:`url(${Image.getFiles(files[1])})`,
                    }} className={"text_im"}/>
                </div>*/}

                <div className={"content"}>

                    <FileUploader
                        children={<FileUpload/>}
                        multiple={true}
                        onTypeError={(err) => {
                            console.error(err)
                            if (err === "File type is not supported")
                                setPopout(<Alert
                                    actions={[
                                        {
                                            title: "Oк",
                                            autoclose: true,
                                            mode: "cancel",
                                        }
                                    ]}
                                    actionsLayout="vertical"
                                    onClose={() => setPopout(null)}
                                    header="Ошибка"
                                    text="Неподдерживаемый тип файла"
                                />)
                        }}
                        onSelect={(file) => {
                            console.log("SELECTED", file)
                        }}
                        classes={"file_uploader"}
                        hoverTitle={" "}
                        id={"okl"}
                        label={"Загрузите или перетащите сюда свои файлы"}
                        handleChange={handleChange} name="file" types={fileTypes}/>
                    <div className={"settings"}>

                        <FormItem style={{height: 80}} className={"default_vls"}>
                            <span className={"font_montserrat w_500 top_subhead"} style={{color: "grey", fontSize: 13}}>
                                    Добавлять новые фото, как:
                                </span>
                            <div style={{
                                display: "flex",
                                height: "100%",
                                width: "100%",
                                alignItems: "center"
                            }} className="default_inputs">

                                <Dropdown controlClassName={"d53"}  className={"d2 select_sizes"} style={{fontSize: 13}}
                                          options={sizes && sizes.length && sizes.map(x => x.label)} onChange={(e) => {
                                    // let d = [...sizes];
                                    let item = sizes.find(x => x.label === e.value)
                                    //let index = d.indexOf(item)
                                    //let c = {...d[0]};
                                    //d[0] = d[index]
                                    // d[index] = c;
                                    setDefaultSize(item)
                                }} value={defaultSize && defaultSize.label}
                                          placeholder="Выберите размер"/>
                                <div style={{width: 10}}/>
                                <Dropdown controlClassName={"d53"}  className={"d2"} style={{fontSize: 13}} options={pols} onChange={(e) => {
                                    /* let d = [...pols];
                                     let index = d.indexOf(e.value)
                                     let c = `${d[0]}`;
                                     d[0] = d[index]
                                     d[index] = c;*/
                                    setDefaultPols(e.value)
                                }} value={defaultPols}
                                          placeholder="Выберите тип"/>
                            </div>
                        </FormItem>
                        <FormItem className={"slider_size"} style={{width: 300, height: 80}}>
                            <div style={{display: "flex", height: "100%", flexDirection: "row", alignItems: "center"}}>
                                <BsImage size={30}/>
                                <Slider handleStyle={[{width:20,height:20,marginTop:0,top:"50%",transform:"translate(-50%,-50%)"}]} onChange={(e) => {
                                    setCanvasWidth(e)
                                }} min={150} max={300}  style={{width: 250, marginRight: 13, marginLeft: 13}} />
                               {/* <Slider
                                    max={300}
                                    min={150}
                                    style={{width: 250, marginRight: 10, marginLeft: 10}}
                                    onChange={(e) => {
                                        setCanvasWidth(e)
                                    }}
                                />*/}
                                <BsImage size={50}/>
                            </div>

                        </FormItem>
                        <span className={"font_montserrat w_700"} style={{
                            color: "red",
                            height: 50,
                            display: "flex",
                            alignItems: "center",
                            fontSize: 13,
                            alignSelf: "center"
                        }}>
                            <BsInfo size={30}/>
                            Чтобы начать сначала - перезагрузите страницу
                        </span>
                    </div>
                    <div className={"files"} style={{marginBottom: 50}}>
                        {files && sizes && sizes.length > 0 && files.length > 0 ?
                            (files.map((item, key) => (
                                <File
                                    defaultSize={defaultSize}
                                    defaultPols={defaultPols}
                                    deleteFile={() => deleteFile(key)} copyFile={() => copyFile(key)} types={types}
                                    saveFile={(field, value) => {
                                        saveFile(key, field, value)
                                    }}
                                    canvasWidth={canvasWidth} sizes={sizes}
                                    openCrop={(saveMe, imageSize, selectedSize, crop, hidden) => openCrop(key, saveMe, imageSize, selectedSize, crop, hidden)}
                                    key={key} selectedFiles={selectedFiles} item={item}
                                    selectFile={() => selectFile(key)} wh={key} photo={item.photo}/>
                            ))) :
                            <Placeholder icon={<VscError size={54} color={"rgb(177,177,177)"}/>}>
                                Пока тут ничего нет, загрузите фотографии
                            </Placeholder>

                        }
                        {/*<File/>*/}
                    </div>
                </div>
                {previewPhoto}
                <div className="hdhdh">
                    <div className={"hdh_info"}>
                        <BiRotateLeft size={50} color={"white"}/>
                        <span className={"font_montserrat w_700"} style={{color:"white"}}>
                            Работать удобнее в вертикальном режиме :-)
                        </span>
                    </div>
                </div>

            </Panel>
        </View>

    )
};

Home.propTypes = {
    id: PropTypes.string.isRequired,
    go: PropTypes.func.isRequired,
    fetchedUser: PropTypes.shape({
        photo_200: PropTypes.string,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        city: PropTypes.shape({
            title: PropTypes.string,
        }),
    }),
};

export default Home;
