import React, {useEffect, useState} from "react"
import {AiOutlineCloseCircle} from "react-icons/ai";
import {
    Button,
    Checkbox,
    FormItem,
    Input,
    Link,
    NativeSelect,
    ScreenSpinner,
    Snackbar,
    Textarea
} from "@vkontakte/vkui";
import InputMask from 'react-input-mask';
import Image from "../rest/Image"
import User from "../rest/User";
import {ReCAPTCHA} from "react-google-recaptcha";

const TakeOrder = ({closePop, callback, openPreview, uniqId, files, types, sizes, shops}) => {
    const [fio, setFio] = useState("");
    const [cost, setCost] = useState(0);
    const [selectShop, setSelectShop] = useState(shops[0]._id)
    const [fioError, setFioError] = useState(false);
    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState(false);
    const [email, setEmail] = useState("");
    const [comment, setComment] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [loading, setLoading] = useState(false)
    const [policy,setPolicy] = useState(false)
    const [loadingPrev,setLoadingPrev] = useState(false)
    const [uploadedFiles,setUploadedFiles] = useState(0)
    const go = async () => {
        let ok = true;
        if (!email || email.indexOf("@") === -1) {
            setEmailError(true)
            ok = false
        }
        if (!phone) {
            setPhoneError(true)
            ok = false
        }
        if (!fio) {
            setFioError(true)
            ok = false
        }
        if (!ok)
            return;

        setLoading(true)

        let photos = []
        let meme = files.filter(x => !x.hasOwnProperty("delete") || !x.delete);
        for (let i = 0; i < meme.length; i++) {
            if (meme[i].polsValue === "С полями") {
                let cnv = meme[i].canvas;
                let dataURL;
                try {
                    dataURL = cnv.toDataURL('image/jpeg');

                }catch (e){
                    console.error("ERROR",e.message)
                    console.error(e)
                }
                let file = {
                    selectedSize: meme[i].selectedSize._id,
                    url: dataURL,
                    count: meme[i].count,
                    photo:meme[i].photo
                };
                await User.sendOrderPhoto(file,uniqId)
                setUploadedFiles(prevState => prevState+1)
                //photos.push(file)
            } else {
                let cnv = meme[i].canvas_bz;

                let dataURL = null
                try {
                    dataURL = cnv.toDataURL('image/jpeg');
                } catch (e) {
                    console.error("ERROR",e.message)
                    console.error(e)
                }
                let file = {
                    selectedSize: meme[i].selectedSize._id,
                    url: dataURL,
                    count: meme[i].count,
                    photo:meme[i].photo
                };
                await User.sendOrderPhoto(file,uniqId)
                setUploadedFiles(prevState => prevState+1)
                //photos.push(file)

            }
        }

        User.createOrder({
            order_id: uniqId,
            email,
            fio,
            shop: shops.find(x => x._id === selectShop),
            phone,
            comment
        }).then((r) => {
            setLoading(false)
            callback(r.data)
        }).catch((err) => {
            alert(`Произошла ошибка - ${err.message}`)
            setLoading(false)
        })

    }
    useEffect(()=>{
        if(loading){

        }
    },[loading])
    const showPrev = () => {
        setLoadingPrev(<Snackbar duration={3000} onClose={()=>{setLoadingPrev(null)}}>Загрузка</Snackbar>)
        let photos = []
        let meme = files.filter(x => !x.hasOwnProperty("delete") || !x.delete);
        for (let i = 0; i < meme.length; i++) {
            if (meme[i].polsValue === "С полями") {
                let cnv = meme[i].canvas;
                let dataURL
                try {
                    dataURL = cnv.toDataURL('image/jpg');
                }catch (e){
                    console.error("ERROR",e.message)
                    console.error(e)
                }

                photos.push({...meme[i], url: dataURL, cnv: cnv})
            } else {
                let cnv = meme[i].canvas_bz;

                let dataURL = null
                try {
                    dataURL = cnv.toDataURL('image/jpg');
                } catch (e) {
                    console.error("ERROR",e.message)
                    console.error(e)
                }
                photos.push({...meme[i], url: dataURL, cnv: cnv})

            }
        }
        openPreview(photos)
        setLoadingPrev(null)
    }
    useEffect(()=>{
        let it = [];

        for (let i = 0; i < sizes.length; i++) {
            it.push({
                label: sizes[i].label,
                size:sizes[i],
                photos: files.filter(x => x.selectedSize._id === sizes[i]._id && !x.delete)
            })
        }
        let all_cost = 0;
        for (let i = 0; i < it.length; i++) {
            let d = it[i].photos.map(x=>x.count || 1)
            d = d.reduce((partialSum, a) => partialSum + a, 0)
            if(d < 11)
                all_cost+= d* it[i].size.cost1_10
            else if(d < 51)
                all_cost+= d*it[i].size.cost11_50
            else if(d < 101)
                all_cost+= d*it[i].size.cost51_100
            else
                all_cost+= d*it[i].size.cost_over_100
        }
        setCost(all_cost)

    },[])
    const onChange = (value) => {

    }
    return (
        <div className={"content take_order_content"}>
            <div className={"header_take_od"} style={{width: "90%", display: "flex", alignContent: "center", justifyContent: "flex-end"}}>
                <span className={"font_montserrat w_700"} style={{fontSize: 30,flex:1, justifySelf: "center"}}>СУММА ЗАКАЗА: {cost} ₽</span>
                <button onClick={closePop}
                        style={{position: "relative", alignSelf: "flex-end", justifySelf: "flex-end"}}
                        className={"close_cropik"}><AiOutlineCloseCircle color={"red"}
                                                                         size={30}/></button>
            </div>
            <div className={"take_order_detail"}>
                {/* <span className={"font_montserrat w_700"} style={{fontSize: 20}}>В ваш заказ входит</span>
                <div className={"order_files_list"}>
                    <div className={"files_list"}>
                        {files && files.map((item, key) => (
                            <div key={key} className={"div_in_list"}>
                                <span className={"font_montserrat w_500"}>
                                    {item.hasOwnProperty("selectedSize") ? item.selectedSize.label : sizes[0].label}&nbsp;
                                    {item.hasOwnProperty("typeValue") ? item.typeValue.label : types[0].label}&nbsp;
                                    {item.hasOwnProperty("count") ? item.count : 1} фото
                                </span>
                            </div>
                        ))

                        }
                    </div>
                    <div className={"files_result_price"}>

                    </div>
                </div>*/}
            </div>
            <div className={"horizontal_line"} style={{}}/>
            <span className={"font_montserrat w_700 c1c1c"} style={{fontSize: 15}}>
                    Мы можем Вам позвонить для уточнения деталей заказа.
                </span>
            <div className={"form_files"}>
                <div className={"main_inputs"} style={{width: "100%", display: "flex", flexDirection: "row"}}>
                    <FormItem top={"Фамилия, Имя, Отчество"}
                              style={{flex: .4}}
                              status={!fioError ? "" : "error"}
                              bottom={
                                  !fioError
                                      ? ""
                                      : "Пожалуйста, введите свое ФИО"
                              }
                    >
                        <Input value={fio} onChange={(e) => {
                            setFioError(false)
                            setFio(e.currentTarget.value)
                        }}/>
                    </FormItem>
                    <FormItem
                        style={{flex: .4}}
                        status={!phoneError ? "" : "error"}
                        bottom={
                            !phoneError
                                ? ""
                                : "Пожалуйста, введите контактный телефон"
                        }
                        top={"Контактный телефон"}>
                        <InputMask mask="+7(999) 999-99-99" value={phone} onChange={(e) => {
                            setPhoneError(false)
                            setPhone(e.currentTarget.value)
                        }}>
                            {(inputProps) => <Input {...inputProps} type="tel" disableUnderline/>}
                        </InputMask>
                    </FormItem>
                    <FormItem
                        style={{flex: .4}}
                        status={!emailError ? "" : "error"}
                        bottom={
                            !emailError
                                ? ""
                                : "Пожалуйста, введите электронную почту"
                        }
                        top={"Email"}>
                        <Input value={email} onChange={(e) => {
                            setEmailError(false)
                            setEmail(e.currentTarget.value)
                        }}/>
                    </FormItem>
                </div>
                <FormItem style={{width: "100%", paddingTop: 0}} top={"Комментарий к заказу"}>
                    <Textarea value={comment} onChange={(e) => setComment(e.currentTarget.value)}/>
                </FormItem>
                <div className={"shop_div"}>
                    <div className={"shopik"}>
                        <FormItem style={{flex:.5,padding:0}} top={"Выберите фотоцентр"}>
                            <NativeSelect value={selectShop} onChange={(e) => setSelectShop(e.target.value)}>
                                {shops && shops.map((item, key) => (
                                    <option key={key} value={item._id}>{item.name}</option>
                                ))}
                            </NativeSelect>
                        </FormItem>
                        {selectShop && selectShop.length > 0 && (
                            <div className={"_shop_info"} style={{display: "flex",paddingLeft:10, flexDirection: "column",flex:.5}}>
                                <div>
                                    <span
                                        className={"font_montserrat w_700"}>{shops.find(x => x._id === selectShop).name}</span>
                                </div>
                                <div>
                                    <span className={"font_montserrat w_500"} style={{marginRight: 5}}>Адрес:</span>
                                    <span style={{color: "grey"}}>{shops.find(x => x._id === selectShop).adres}</span>
                                </div>
                                <div>
                                    <span className={"font_montserrat w_500"} style={{marginRight: 5}}>Телефон:</span>
                                    <span style={{color: "grey"}}>{shops.find(x => x._id === selectShop).phone}</span>
                                </div>
                                <div>
                                    <span className={"font_montserrat w_500"}
                                          style={{marginRight: 5}}>График работы:</span>
                                    <span style={{color: "grey"}}>{shops.find(x => x._id === selectShop).time}</span>
                                </div>
                            </div>
                        )

                        }
                    </div>

                </div>

            </div>
            <Checkbox onClick={()=>setPolicy(prevState => !prevState)} className={"font_montserrat w_700 pol_se"} style={{marginTop:10,fontWeight:700}} checked={policy}>
                Согласие с&nbsp;
                <Link target={"_blank"} href={"https://www.хорошо-нн.рф/policy"}>Политикой в отношении обработки персональных данных</Link>.
            </Checkbox>
            <ReCAPTCHA
                size={"normal"}
                sitekey="6LfZh94eAAAAAI2jPsQsYiDWFC7P3d0xWDyoICer"
                onChange={onChange}
            />
            <div style={{width: "100%", display: "flex",alignItems:"center",justifyContent:"center", flexDirection: "row"}}>
                <Button style={{width: 300,maxWidth:300, margin: 10}} stretched size={"m"} mode={"outline"} onClick={showPrev}>Мои
                    фотографии</Button>

                <Button disabled={!policy} onClick={go} stretched style={{width: 300,maxWidth:300, margin: 10}} size={"m"}
                        mode={"commerce"}>Заказать</Button>
            </div>
            {loading &&
            <div className={"loading_order"}>
                <span className={"font_montserrat w_500"} style={{fontSize: 30, marginBottom: 150}}>Загрузка</span>
                <ScreenSpinner size={"large"}/>
                <span>Не закрывайте страницу, загружаем фото {uploadedFiles}/{files.filter(x => !x.hasOwnProperty("delete") || !x.delete).length}.</span>
            </div>
            }
            {loadingPrev}
        </div>
    )
}
export default TakeOrder