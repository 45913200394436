import React, {useEffect, useState} from "react";
import {IconButton, Panel} from "@vkontakte/vkui";
import {BsArrowLeft} from "react-icons/bs";

const PreviewPhotos = ({photos, sizes, id, goBack}) => {
    const [sortedPhotos, setSortedPhotos] = useState([])
    useEffect(() => {
        let it = [];

        for (let i = 0; i < sizes.length; i++) {
            it.push({
                label: sizes[i].label,
                photos: photos.filter(x => x.selectedSize._id === sizes[i]._id)
            })
        }
        setSortedPhotos([...it])
    }, [])
    return (
        <Panel id={id}>
            <div className={"preview_panel_modal vkuiView__popout View__popout"}>

                <div className={"header_prrev"}>
                    <IconButton onClick={() => goBack()} style={{
                        width: 40,
                        height: 40,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <BsArrowLeft size={25}/>
                    </IconButton>
                    <span className={"font_montserrat w_700"} style={{fontSize: 25, marginLeft: 10}}>Ваш заказ:</span>
                </div>
                <div className={"preview_div"}>
                    {sortedPhotos && sortedPhotos.map((item, key) => {
                        if (item.photos.length > 0)
                            return (
                                <div className={"photos_sort"}>
                                    <span className={"font_montserrat w_700"}>{item.label}</span>
                                    <div className={"photos_labl"}>
                                        {item.photos && item.photos.map((item, key) => {
                                            let width
                                            let height
                                            if (item.cnv.width >= item.cnv.height) {
                                                width = item.selectedSize.height
                                                height = item.selectedSize.width
                                            } else {
                                                width = item.selectedSize.width
                                                height = item.selectedSize.height
                                            }
                                            return (
                                                <div key={key} className={"preview_photo"}>

                                                    <img width={width} height={height} src={item.url}
                                                         className={"preview_img_"}/>
                                                    <div className={"prev_info"}>
                                {/*<span
                                    className={"font_montserrat w_500"}>Вид печати: {item.selectedSize && item.selectedSize.label}</span>*/}
                                                        <span
                                                            className={"font_montserrat w_500"}>{item.count ? item.count : 1} шт.</span>
                                                    </div>

                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                    })}
                </div>
            </div>
        </Panel>

    )
}
export default PreviewPhotos