import axios from 'axios';
import http from "./create_axios";

class Admin {
    auth(pass){
        return http.post("/api",{type:"auth",pass:pass});
    }
    getInfo(){
        return http.post("/api",{type:"get_information"});
    }
    sendRequest(data){
        return http.post("/api",data);
    }

    disableSize(id) {
        return http.post("/api",{type:"disable_size",_id:id});
    }
}
export default new Admin();