import React, {useEffect, useState} from 'react';
import uniqid from 'uniqid';
import {AdaptivityProvider, AppRoot, View} from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';

import Home from './panels/Home';
import Admin from "./rest/Admin";
import AdminPanel from "./panels/AdminPanel";

let url = new URL(window.document.location.href);
let uniqId = uniqid(String(Date.now()) + "_");
const App = () => {
	const [activePanel, setActivePanel] = useState('home');
	const [fetchedUser, setUser] = useState(null);
	const [popout, setPopout] = useState(null);

	useEffect(()=>{
		if( url.hash.length > 0 && url.hash === "#admin"){
			if(document.cookie.indexOf("auth=ok") !== -1)
			{
				setActivePanel("admin")
			}
			else{
				let pass = prompt("Введите пароль для входа в админку");
				if(pass){
					Admin.auth(pass).then((r)=>{
						if(r.data.auth) {
							setActivePanel("admin")
							let now = new Date();
							let time = now.getTime();
							let expireTime = time + 1000*36000;
							now.setTime(expireTime);
							document.cookie = 'auth=ok;expires='+now.toUTCString()+';path=/';
						}
						else
							alert("Неверный пароль")
					}).catch(()=>{
						alert("Ошибка авторизации")
					})
				}
			}
		}else{
			window.location.hash = uniqId;
		}
	},[])

	const go = e => {
		setActivePanel(e.currentTarget.dataset.to);
	};

	return (
		<AdaptivityProvider>
			<AppRoot>
				<View activePanel={activePanel} popout={popout}>
					<Home uniqId={uniqId} id='home' fetchedUser={fetchedUser} go={go} />
					<AdminPanel id='admin' fetchedUser={fetchedUser} go={go} />
				</View>
			</AppRoot>
		</AdaptivityProvider>
	);
}

export default App;
